<template>
  <div class="t-fale-conosco wrapper" id="wrapper">
    <OHeader showBottomLogo bottomLogo="default" />
    <div class="">
      <OMainFaleConosco />
      <OFooter :showContactBlock="false" />
    </div>
  </div>
</template>

<script>
import OFooter from "@/components/organisms/Footer.vue";
import OHeader from "@/components/organisms/Header.vue";
import OMainFaleConosco from "@/components/organisms/MainFaleConosco.vue";

export default {
  name: "TFaleConosco",
  components: {
    OFooter,
    OHeader,
    OMainFaleConosco
  }
};
</script>

<style lang="scss">
body {
  padding: 0 !important;
  margin: 0 !important;
  overflow-x: hidden !important;
}

.page-sobre-o-programa {
  color: $lochmara;

  p {
    line-height: pxToEm(26px);
  }
}

.b-programs {
  z-index: 6;
}

.t-fale-conosco {
  #header {
    background: none;
    position: relative;

    .columns {
      margin-bottom: 0;
    }

    .a-logo-bottom {
      img {
        max-width: 300px;
        width: 64%;
      }
    }
  }

  .m-section-title__title {
    font-weight: 600;
  }

  .m-video {
    &::before {
      @extend %position-absolute;
      @extend %pseudo-el;
      background: rgb(41, 136, 195);
      background: radial-gradient(
        circle,
        rgba(41, 136, 195, 1) 0%,
        rgba(24, 100, 147, 1) 100%
      );
      display: block;
      height: calc(100% + 1px);
      opacity: 0.55;
      pointer-events: none;
      width: 100%;
      z-index: 2;
    }

    &.is-playing {
      &::before {
        display: none;
      }
    }
  }

  .o-fale-conosco {
    background-color: #0275bf;
    border-radius: 10px;
    color: $white;

    .play {
      @extend %position-absolute;
      height: 60px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
    }

    .m-video {
      box-shadow: 0 20px 30px #00000030;
    }
  }

  .m-video {
    margin: 0 auto;
  }
}

.b-footer-contact {
  .m-section-title__title {
    color: #0275bf;
  }
}

@media (min-width: 800px) and (min-height: 600px) and (orientation: landscape) {
  .t-fale-conosco {
    #header {
      // height: 30vh;
    }
  }
}

@include media(">=tablet") {
  .t-fale-conosco {
    #header {
      // height: 74vh;

      .logo-bottom-wrapper {
        bottom: 120px;

        img {
          max-width: 230px;
        }
      }
    }

    .o-fale-conosco {
      margin-left: auto;
      margin-right: auto;
      max-width: 1334px;
      padding-left: 4rem !important;
      padding-right: 4rem !important;
      width: 80%;

      .play {
        height: 80px;
        width: 80px;
      }
    }
  }
}

@include media(">=desktop") {
  .t-fale-conosco {
    .response-fale-conosco {
      margin-top: -250px !important;
    }

    .logo-bottom-wrapper {
      pointer-events: none;
      z-index: -1;
    }
  }

  .b-fale-conosco {
    margin-top: -34vh;
    z-index: 5;

    .m-section-title__title {
      color: $white;
    }
  }

  .o-fale-conosco {
    padding-left: 6rem !important;
    padding-right: 6rem !important;

    .play {
      height: 100px;
      width: 100px;
    }
  }
}

@include media(">=widescreen") {
  .t-fale-conosco {
    .response-fale-conosco {
      margin-top: -150px !important;
    }

    .o-fale-conosco {
      padding-left: 7rem !important;
      padding-right: 7rem !important;
    }

    .b-fale-conosco {
      margin-top: -38vh;

      .o-fale-conosco {
        padding-bottom: 14rem !important;
      }
    }
  }
}

@include media(">=fullhd") {
  .t-fale-conosco {
    .response-fale-conosco {
      margin-top: -20px !important;
    }
    #header {
      // height: 74vh;
    }

    .o-fale-conosco {
      padding-left: 12rem !important;
      padding-right: 12rem !important;
    }
  }
}
</style>
