<template>
  <TFaleConosco />
</template>

<script>
import TFaleConosco from "@/components/templates/FaleConosco.vue";

export default {
  components: {
    TFaleConosco
  },
  name: "PFaleConosco"
};
</script>
